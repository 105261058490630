<template>
  <v-footer
    color="primary lighten-1"
    padless
  >
    <v-row
      justify="center"
      no-gutters
    >
      <v-btn
        color="white"
        text
        rounded
        class="my-2"
        :to="{ name: 'Home'}"
      >
        Home
      </v-btn>
      <v-btn
        color="white"
        text
        rounded
        class="my-2"
        :to="{ name: 'PrivacyPolicy'}"
      >
        Privacy Policy
      </v-btn>
      <v-col
        class="primary lighten-2 py-4 text-center white--text"
        cols="12"
      >
        {{ new Date().getFullYear() }} — <strong>RubberPortal</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: 'footer',
  data: () => ({
  })
}
</script>
