<template>
  <v-container>
    <h2 class="text-center">おすすめ裏ラバー</h2>
    <div v-for="item in osusumeuralist" :key="item.rubber_name">
      <RubberCard :name="item.rubber_name"/>
    </div>
  </v-container>
</template>

<script>
import RubberCard from '@/components/RubberCard.vue'

export default {
  name: 'OsusumeUra',
  components: {
    RubberCard
  },
  data: function () {
    return {
      osusumeuralist: [
        { rubber_name: 'テナジー05' },
        { rubber_name: 'ディグニクス80' },
        { rubber_name: 'エボリューション MX-D' },
        { rubber_name: 'ファスターク G-1' },
        { rubber_name: 'V>15 Extra' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h2 {
  font-size: 36px;
  color: white;
  background-color: #448AFF;
}
</style>
