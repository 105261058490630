<template>
  <v-container>
    <h1 class="text-center">卓球ラバー<br>性能・比較まとめ</h1>
    <p class="text-center mx-5">
      このサイトでは、各メーカーのデータや、実際に使用している人のレビューを参考に、
      ラバーの性能や特徴を独自にまとめました。ラバー購入の際の参考にしてください。（ラバーは随時追加予定）
    </p>
  </v-container>
</template>

<script>
export default {
  name: 'Title'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h1 {
  margin: 40px 0 0;
}
</style>
