import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const config = {
  apiKey: 'AIzaSyBvnBRsBd8Vgw0e8rn3qzmAB1KgnXZNsGk',
  authDomain: 'rubber-portal.firebaseapp.com',
  projectId: 'rubber-portal',
  storageBucket: 'rubber-portal.appspot.com',
  messagingSenderId: '125710446749',
  appId: '1:125710446749:web:e9d8c5025dd5a81549e474',
  measurementId: 'G-RC6B7QG5X8'
}

firebase.initializeApp(config)
const db = firebase.firestore()

export default db
