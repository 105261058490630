<template>
  <v-container>
    <v-card elevation="10" class="indigo lighten-4">
      <v-col align="center">
        <h3>{{ name }}</h3>
        <v-card-text>
          {{setumei}}
        </v-card-text>
        <div v-html="image"></div>
        <p>※画像クリックでAmazonの販売ページへ</p>
        <svg width="240" height="120"  viewBox="0 0 240 120" >
          <rect x="100" y="0" width="120" height="30" style="fill:brack"></rect>
          <rect x="100" y="40" width="120" height="30" style="fill:brack"></rect>
          <rect x="100" y="0" :width="speed-20" height="30" style="fill:red"></rect>
          <rect x="100" y="40" :width="spin-20" height="30" style="fill:red"></rect>
          <text x="0" y="20" font-size="16" stroke="black" text-anchor="start" stroke-width="0.5" >スピード：</text>
          <text x="0" y="60" font-size="16" stroke="black" text-anchor="start" stroke-width="0.5" >&emsp;スピン：</text>
          <text x="0" y="100" font-size="16" stroke="black" text-anchor="start" stroke-width="0.5" >打球感（硬さ）：&emsp;{{soft}}</text>
        </svg>
        <h4> 定価: {{teika}} 円</h4>
        <v-row class="justify-center">
          <v-btn
            class="ma-5"
            color="primary"
            elevation="4"
            :to="{ name: 'RubberDetails', params: {name: name}}"
          >詳細</v-btn>
        </v-row>
      </v-col>
    </v-card>
  </v-container>
</template>

<script>
import db from '@/firebase/firebase.js'

export default {
  name: 'RubberCard',
  props: {
    name: String
  },
  data: function () {
    return {
      setumei: null,
      image: null,
      items: null,
      speed: null,
      spin: null,
      soft: null,
      teika: null
    }
  },
  created () {
    this.getData()
  },
  methods: {
    getData: async function () {
      // データ取得
      await db.collection('rubbers').doc(this.name).get().then((doc) => {
        if (doc.exists) {
          console.log('Document data:', doc.data())
          this.items = doc.data()
        } else {
          // doc.data() will be undefined in this case
          console.log('No such document!')
        }
      }).catch((error) => {
        console.log('Error getting document:', error)
      })
      this.setumei = this.items.setumei1
      this.image = this.items.image_html
      this.speed = this.items.speed
      this.spin = this.items.spin
      this.soft = this.items.soft
      this.teika = this.items.teika
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 0px;
  font-size: 30px;
  color: white;
  background-color: #3D5AFE;
}
h4 {
  margin: 0px;
  font-size: 22px;
}
</style>
