<script>
import { Scatter } from 'vue-chartjs'

export default {
  extends: Scatter,
  name: 'chart',
  data () {
    return {
      data: {
        datasets: [
          {
            label: 'テナジー05',
            data: [{ x: 130, y: 115 }],
            backgroundColor: [
              'rgb(255, 160, 0)'
            ]
          },
          {
            label: 'ディグニクス05',
            data: [{ x: 135, y: 120 }],
            backgroundColor: [
              'rgb(255, 160, 0)'
            ]
          },
          {
            label: 'ディグニクス80',
            data: [{ x: 137.5, y: 117.5 }],
            backgroundColor: [
              'rgb(255, 160, 0)'
            ]
          },
          {
            label: 'ディグニクス09C',
            data: [{ x: 130, y: 130 }],
            backgroundColor: [
              'rgb(255, 160, 0)'
            ]
          },
          {
            label: 'ロゼナ',
            data: [{ x: 130, y: 108 }],
            backgroundColor: [
              'rgb(255, 160, 0)'
            ]
          },
          {
            label: 'アイビス',
            data: [{ x: 105, y: 110 }],
            backgroundColor: [
              'rgb(255, 160, 0)'
            ]
          },
          {
            label: 'エボリューション MX-D',
            data: [{ x: 130, y: 110 }],
            backgroundColor: [
              'rgb(255, 160, 0)'
            ]
          },
          {
            label: 'エボリューション MX-P',
            data: [{ x: 120, y: 105 }],
            backgroundColor: [
              'rgb(255, 160, 0)'
            ]
          },
          {
            label: 'クァンタムX プロ',
            data: [{ x: 110, y: 100 }],
            backgroundColor: [
              'rgb(255, 160, 0)'
            ]
          },
          {
            label: 'ズィーガーPK50',
            data: [{ x: 123, y: 115 }],
            backgroundColor: [
              'rgb(255, 160, 0)'
            ]
          },
          {
            label: 'ファスターク G-1',
            data: [{ x: 125, y: 113 }],
            backgroundColor: [
              'rgb(255, 160, 0)'
            ]
          },
          {
            label: 'V>15 Extra',
            data: [{ x: 125, y: 110 }],
            backgroundColor: [
              'rgb(255, 160, 0)'
            ]
          },
          {
            label: 'VENTUS Extra',
            data: [{ x: 128, y: 110 }],
            backgroundColor: [
              'rgb(255, 160, 0)'
            ]
          },
          {
            label: 'V>11 Extra',
            data: [{ x: 128, y: 105 }],
            backgroundColor: [
              'rgb(255, 160, 0)'
            ]
          }
        ]
      },
      options: {
        scales: {
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Speed'
            }
          }],
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Spin'
            },
            ticks: {
              beginAtZero: false,
              stepSize: 5
            }
          }]
        },
        tooltips: {
          backgroundColor: 'rgba(19, 56, 95, 0.9)',
          callbacks: {
            // title: function (tooltipItem, data) {
            //   return 'テナジー'
            // },
            label: function (tooltipItem, data) {
              var label = data.datasets[tooltipItem.datasetIndex].label
              // var x = tooltipItem.label
              // var y = tooltipItem.value
              return label
            }
          }
        },
        legend: {
          display: false
        },
        elements: {
          point: {
            radius: 8,
            hoverRadius: 8
          }
        },
        onClick: this.handleChartClick
      }
    }
  },
  mounted () {
    this.renderChart(this.data, this.options)
  },
  methods: {
    // クリックイベント
    handleChartClick (evt, elements) {
      var canvas = this.$data._chart
      const chartIndex = canvas.getElementAtEvent(evt)
      if (chartIndex.length !== 0) {
        const datasetIndex = chartIndex[0]._datasetIndex
        console.log(this.data.datasets[datasetIndex].label)
        // window.open(this.data.datasets[datasetIndex].url, '_blank')
        this.$router.push({
          name: 'RubberDetails', params: { name: this.data.datasets[datasetIndex].label }
        })
        // const routeData = this.$router.resolve({
        //   name: 'RubberDetails', params: { name: this.data.datasets[datasetIndex].label }
        // })
        // window.open(routeData.href, '_blank')
      } else {
        console.log('Background clicked')
      }
    }
  }
}
</script>
