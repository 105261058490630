<template>
  <v-container>
    <v-container class="blue accent-2"></v-container>
    <v-img
          alt="Vuetify Logo"
          class="mx-auto"
          contain
          src="@/assets/raiselogo.png"
          transition="scale-transition"
          width="300"
          algin="center"
          backgroundcolor="black"
        />
    <Title />
    <div>
      <h2 class="text-center">ラバー性能表</h2>
    </div>
    <v-container></v-container>
    <Chart></Chart>
    <OsusumeUra />
    <VueAdsense
      ins-style="display:block"
      data-ad-client="ca-pub-3524335930996757"
      data-ad-slot="8035989926"
      data-ad-format="auto"
      data-full-width-responsive="yes">
    </VueAdsense>
    <Footer />
  </v-container>
</template>

<script>
// @ is an alias to /src
import Title from '@/components/Title.vue'
import OsusumeUra from '@/components/OsusumeUra.vue'
import Chart from '@/components/Chart.vue'
import Footer from '@/components/Footer.vue'
import VueAdsense from 'vue-adsense'

export default {
  name: 'Home',
  components: {
    Title,
    OsusumeUra,
    Chart,
    Footer,
    VueAdsense
  }
}
</script>

<style scoped lang="scss">
h2 {
  font-size: 36px;
  color: white;
  background-color: #448AFF;
}
</style>
